<template>
  <div class="pa-3">
    <v-card
      elevation="0"
      :loading="isAssigningAddress"
      class="ma-auto"
      max-width="800"
    >
      <v-card-title>
        {{ localizedText[language].checkout }}
      </v-card-title>
      <v-stepper color="black" v-model="e1">
        <v-stepper-header>
          <v-stepper-step editable :complete="e1 > 0" step="1">
            {{ localizedText[language].deliveryAddress }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            v-if="cart.showDeliverySpeedOption"
            :editable="e1 > 1"
            :complete="e1 > 2"
            step="2"
          >
            {{ localizedText[language].deliveryOption }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :step="lastStep">
            {{ localizedText[language].summary }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <AddressBookComponent
              v-on:deliver-to="deliverToAddress($event)"
              :isCheckout="true"
            />
          </v-stepper-content>

          <v-stepper-content v-if="cart.showDeliverySpeedOption" step="2">
            <DeliverySpeedComponent
              v-on:speed-option-selected="selectDeliverySpeed($event)"
            />
          </v-stepper-content>

          <v-stepper-content :step="lastStep">
            <CheckoutSummaryComponent />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddressBookComponent from "@/components/AddressBookComponent";
import DeliverySpeedComponent from "@/components/DeliverySpeedComponent";
import CheckoutSummaryComponent from "@/components/CheckoutSummaryComponent";

export default {
  name: "Checkout",
  components: {
    AddressBookComponent,
    DeliverySpeedComponent,
    CheckoutSummaryComponent,
  },
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      countryCode: "countryCode",
      customerInfo: "customerInfo",
      currency: "currency",
    }),
    ...mapGetters("Cart", {
      cart: "cart",
    }),

    lastStep: function () {
      return this.cart.showDeliverySpeedOption ? 3 : 2;
    },
  },
  //********************** */
  data: () => ({
    isAssigningAddress: false,
    e1: 1,
    localizedText: {
      en: {
        deliveryAddress: "Delivery Address",
        deliveryOption: "Delivery Option",
        summary: "Summary",
        myAddresses: "My address book",
        checkout: "Checkout",
      },
      ar: {
        deliveryAddress: "عنوان التوصيل",
        deliveryOption: "نوع التوصيل",
        summary: "الملخص",
        myAddresses: "سجل العناوين",
        checkout: "اتمام الطلب",
      },
    },
  }),

  methods: {
    deliverToAddress: async function (address) {
      let model = {
        addressType: address.addressType,
        firstName: address.firstName,
        middleName: address.middleName,
        lastName: address.lastName,
        email: address.email,
        countryCode: address.countryCode,
        country: address.country,
        town: address.town,
        area: address.area,
        postalCode: address.postalCode,
        phone: address.phone,
        secondPhoneNumber: address.secondPhone,
        government: address.government,
        block: address.block,
        avenue: address.avenue,
        street: address.street,
        house: address.house,
        flat: address.flat,
        floor: address.floor,
        building: address.building,
        office: address.office,
        line1: address.line1,
        line2: address.line2,
      isGift: address.isGift,
      isGiftAndAddressUnkown: address.isGiftAndAddressUnkown,
      phoneOfGiftRecipient: address.phoneOfGiftRecipient,
      nameOfGiftRecipient: address.nameOfGiftRecipient,
        deliveryInstructions: address.deliveryInstructions,
        language: this.language,
      };
      try {
        this.isAssigningAddress = true;
        await this.$store.dispatch("Cart/assignShippingAddress", model);
        await this.loadDeliverySpeedOptions();
        this.isAssigningAddress = false;
        this.e1 = 2;
      } catch (error) {
        this.isAssigningAddress = false;
        console.log(error);
      }
    },
    loadDeliverySpeedOptions: async function () {
      await this.$store.dispatch("Catalog/loadDeliverySpeedOptions");
    },
    selectDeliverySpeed: async function (selectedOption) {
      let model = {
        deliverSpeedOptionId: selectedOption.deliverSpeedOptionId,
        deliveryDate: selectedOption.deliveryDate,
        deliveryTimeRange: selectedOption.deliveryTimeRange,
        language: this.language,
      };

      try {
        this.isAssigningAddress = true;
        await this.$store.dispatch("Cart/selectDeliverySpeed", model);
        this.e1 = 3;
        this.isAssigningAddress = false;
      } catch (error) {
        this.isAssigningAddress = false;
        console.log(error);
      }
    },
  },

  async mounted() {
    await this.$store.dispatch("Catalog/loadPaymentMethods", this.countryCode);
    await this.$store.dispatch("Account/loadCustomerInfo");
    window.snaptr('track', 'PAGE_VIEW');
    window.snaptr('track', 'START_CHECKOUT');
    
  },
};
</script>

<style>
</style>