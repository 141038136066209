<template>
  <div>
    <v-card class="mx-1 my-1">
      <v-card-text>
        <div>{{ localizedText[language].chooseDeliveryOption }}</div>
        <v-radio-group v-model="selectedOptionId">
          <v-radio
            color="black lighten-1"
            v-for="option in deliverySpeedOptions"
            :key="option.id"
            :value="option.id"
          >
            <template v-slot:label>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title
                    >{{ option.title | localizedText(language) }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >{{ option | displayCost(currency, language) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>

    <v-card v-if="hasDateOption" class="mx-1 my-1">
      <v-card-text>
        <v-checkbox
          color="black lighten-1"
          v-model="chooseDeliveryTime"
          :label="localizedText[language].chooseSpecificTime"
        >
        </v-checkbox>

        <v-dialog
          v-if="chooseDeliveryTime"
          ref="dialog"
          v-model="showDatePicker"
          :return-value.sync="selectedDate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="selectedDate"
              :label="localizedText[language].pickADate"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            color="black lighten-1"
            header-color="black"
            :locale="locale"
            :min="minDate"
            :show-current="false"
            v-model="selectedDate"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="showDatePicker = false">
              {{ localizedText[language].cancel }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(selectedDate)"
            >
              {{ localizedText[language].ok }}
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-select
          v-model="selectedTime"
          v-if="chooseDeliveryTime"
          :items="times"
          :label="localizedText[language].pickATime"
        ></v-select>
      </v-card-text>
    </v-card>

    <v-btn
      :disabled="selectedOptionId < 0"
      block
      dark
      @click="selectDeliverOption()"
      >{{ localizedText[language].next }}</v-btn
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DeliverySpeedComponent",

  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      countryCode: "countryCode",
      customerInfo: "customerInfo",
      currency: "currency",
    }),

    ...mapGetters("Catalog", {
      deliverySpeedOptions: "deliverySpeedOptions",
    }),

    hasDateOption: function () {
      const selectedOptionId = this.selectedOptionId;

      let selectedOption = this.deliverySpeedOptions.find((element) => {
        return element.id == selectedOptionId;
      });

      if (selectedOption) {
        return selectedOption.hasDateOption;
      }

      return false;
    },
    minDate: function () {
      const selectedOptionId = this.selectedOptionId;

      let selectedOption = this.deliverySpeedOptions.find((element) => {
        return element.id == selectedOptionId;
      });

      let today = new Date();

      let result = new Date();

      result.setDate(today.getDate() + selectedOption.minimumDaysFromToday);

      return result.toISOString().substr(0, 10);
    },

    locale: function () {
      return this.language == "en" ? "en-us" : "ar-kw";
    },
  },
  data: () => ({
    chooseDeliveryTime: false,
    selectedDate: null,
    selectedTime: null,
    showDatePicker: false,
    times: [],
    selectedOptionId: -1,

    localizedText: {
      en: {
        next: "Next",
        chooseDeliveryOption: "Choose delivery option:",
        chooseSpecificTime: "Choose a specific time for delivery",
        pickADate: "Pick a date",
        pickATime: "Pick a time",
        ok: "Ok",
        cancel: "Cancel",
      },
      ar: {
        next: "التالي",
        chooseDeliveryOption: "اختر نوع التوصيل:",
        chooseSpecificTime: "اختيار وقت محدد للتوصيل",
        pickADate: "اختر اليوم",
        pickATime: "اختر الوقت",
        ok: "تم",
        cancel: "الغاء",
      },
    },
  }),

  methods: {
    loadDeliverySpeedOptions: async function () {
      await this.$store.dispatch("Catalog/loadDeliverySpeedOptions");
    },
    loadTimeRanges: function () {
      let isEn = this.language == "en";
      let id = this.selectedOptionId;
      let selectedOption = this.deliverySpeedOptions.find((element) => {
        return element.id == id;
      });
      if (selectedOption) {
        console.log(selectedOption);
        if (selectedOption.hasDateOption) {
          let timeRanges = selectedOption.timeRanges.map((t) =>
            isEn ? t.english : t.arabic
          );
          this.times = timeRanges;
        }
      } else {
        this.times = [];
      }
    },

    selectDeliverOption: function () {
      let model = {
        deliverSpeedOptionId: this.selectedOptionId,
        deliveryDate: this.selectedDate,
        deliveryTimeRange: this.selectedTime,
      };
      this.$emit("speed-option-selected", model);
    },
  },

  async mounted() {
    await this.loadDeliverySpeedOptions();
    this.loadTimeRanges();
  },

  filters: {
    displayCost: function (option, currency, language) {
      if (option.costs == 0) {
        return language == "en" ? "Free" : "مجانا";
      }

      var costs = option.costs * currency.value;

      let additionalCostsLabel =
        language == "en" ? "Additional costs: " : "تكلفة اضافية: ";

      let result = `${additionalCostsLabel} ${costs.toFixed(2)} ${
        currency.symbol
      }`;

      return result;
    },
    localizedText: function (text, language) {
      if (language == "en") {
        return text.english;
      } else {
        return text.arabic;
      }
    },
  },

  watch: {
    selectedOptionId: function (val) {
      this.loadTimeRanges();
    },
  },
};
</script>

<style>
</style>